/*

@@@               @@
@@@@@           @@@@
@@@@@@#       @@@@@@
@@@@@@@@&   @@@@@@@@
@@@@/@@@@@@@@@@ @@@@
@@@@/  %@@@@@   @@@@
@@@@/    (@     @@@@
@@@@/  @&   @@  @@@@

Author: dbl._m

*/
/*=============================================
=            TABLE OF CONTENTS            =
===============================================
	0. 	CSS Reset
	1. 	Document Setup
	2. 	Globals
	3. 	Site Header
	4.  Sections
	5. 	Modules
	6. 	404 Page
	7.  Impressum - Datenschutz
	8. 	Site Footer
	9. 	Media Queries
=============================================*/

/*=============================================
=            0. CSS Reset            =
=============================================*/

/*=====  End of 0. CSS Reset  ======*/

/* fix for AOS animations */
.col-12,
.sticky,
figure,
.sub-footer {
  overflow: hidden !important;
}

/*=============================================
=            1. Document Setup            =
=============================================*/

/* bootstrap */
@import "node_modules/bootstrap/scss/bootstrap";

/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* Colors */
$primary: #2da05f;
$primary-hover: #185332;
$lightgray: #fbf9fb;
$bg-lightgray: #f2f2f2;
$white: #fff;
$ba-black: #191919;

/* Section Spacing */
$section-padding-top-dektop: 120px;
$section-padding-bottom-dektop: 120px;
$section-padding-top-mobile: 60px;
$section-padding-bottom-mobile: 60px;

/* Smooth Scroll behavior - BUT ANCHOR PROBLEM IN CHROME! */
/* html {
  scroll-behavior: smooth;
}  */

body {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Poppins";
  color: $ba-black;
  font-weight: 300;
}
html,
body {
  position: relative !important;
  height: 100% !important;
}

/* Typo */
p,
tr,
td {
  font-size: 1.25rem;
  line-height: 1.6875rem;
}

a {
  text-decoration: unset;
}
.overline {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px !important;
  letter-spacing: 0em;
}

::selection,
::-moz-selection {
  color: white;
  background: $primary-hover;
}

/* Anchorlink / Ankerlink offset */
:target::before {
  content: "";
  display: block;
  height: 100px;
  margin-top: -100px;
}

@media only screen and (max-width: 992px) {
  :target::before {
    content: "";
    display: block;
    height: 60px;
    margin-top: -60px;
  }
}

:focus {
  outline: none !important;
}

/*=====  End of 1. Document Setup  ======*/

/*=============================================
    =            2. Globals            =
    =============================================*/
h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 100%;
}
h1,
.h1-style {
  font-size: 78px;
  margin-bottom: 24px;
  font-size: clamp(2.5rem, calc(4vw + 1rem), 5rem);
  line-height: clamp(2.75rem, calc(4vw + 1rem), 5.5rem);
}
h2 {
  font-size: 52px;
  margin-bottom: 32px;
  font-size: clamp(1.625rem, calc(4vw + 1rem), 3.25rem);
  line-height: clamp(2.1375rem, calc(4vw + 1rem), 4.275rem);
  @include media-breakpoint-down(md) {
    margin-top: 2.5rem;
  }
}
h3 {
  font-size: 32px;
  line-height: 39px;
}
h4 {
  font-size: 24px;
  line-height: 160%;
}
.font-italic {
  font-style: italic;
}

.btn {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.35rem;
  text-align: center;
  border-color: $primary;
  background-color: $primary;
  border: 2px solid transparent;
  border-radius: 48px;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  -ms-border-radius: 48px;
  -o-border-radius: 48px;
  &:hover {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }
}

.btn-scroll-down {
  position: fixed;
  top: 80%;
  left: -50%;
  transform: translate(-50%, 0%);
  display: none;
  transition: left 0.5s ease-out;
  -webkit-transition: left 0.5s ease-out;
  -moz-transition: left 0.5s ease-out;
  -ms-transition: left 0.5s ease-out;
  -o-transition: left 0.5s ease-out;
  z-index: 1;

  &.show {
    left: 50%;
  }
}

.btn.arrow-right {
  &:hover::after {
    content: "";
    opacity: 1;
    content: url("../img/arrow-right.svg");
    filter: brightness(0) invert(1);
    margin-left: 10px;
    height: 20px;
    width: 20px;
    transition: opacity 0.8s ease, width 0.1s ease;
    -webkit-transition: opacity 0.8s ease, width 0.1s ease;
    -moz-transition: opacity 0.8s ease, width 0.1s ease;
    -ms-transition: opacity 0.8s ease, width 0.1s ease;
    -o-transition: opacity 0.8s ease, width 0.1s ease;
  }
  &::after {
    content: "";
    opacity: 0;
    display: inline-block;
    position: relative;
    height: 0px;
    width: 0px;
    top: 2px;
    right: 0px;
    transition: opacity 0.5s ease, width 0.5s ease;
    -webkit-transition: opacity 0.5s ease, width 0.5s ease;
    -moz-transition: opacity 0.5s ease, width 0.5s ease;
    -ms-transition: opacity 0.5s ease, width 0.5s ease;
    -o-transition: opacity 0.5s ease, width 0.5s ease;
  }
}

.mail-link {
  text-transform: none;
  text-decoration: none;
}
.bg-lightgray {
  background-color: $bg-lightgray;
}
.bg-green {
  background-color: $primary;
}

.bi-arrow-up-right-circle,
.bi-arrow-down-circle {
  font-size: 44px;
}
.bi-arrow-down-circle {
  color: $lightgray;
}
.bi-arrow-up-right-circle {
  color: $primary;
}

footer,
.nav-contact-wrapper {
  ol,
  ul {
    padding-left: 0rem;
    li {
      list-style-type: none;
    }
  }
}

.btn-outline-primary {
  color: $primary;
  border-color: $primary;
  background-color: transparent;
}
.btn-outline-light {
  color: $white;
  border-color: $white;
  background-color: transparent;
}
button.btn-outline-primary,
button.btn-outline-light {
  margin-top: 24px;
}

/* links hover anim */
a.nav-link {
  display: inline-block;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  -webkit-transition: border 400ms ease;
  -moz-transition: border 400ms ease;
  -ms-transition: border 400ms ease;
  -o-transition: border 400ms ease;
  transition: border 400ms ease;
}

a.nav-link:hover,
a.nav-link:active {
  margin-bottom: 8px;
  padding-bottom: 0px;
  border-bottom: 1px solid black;
}

.overflow-hidden {
  overflow: hidden;
}

@include media-breakpoint-up(lg) {
  .overflow-lg-hidden {
    overflow: hidden;
  }
}

path {
  transition: fill 0.4s ease;
  -webkit-transition: fill 0.4s ease;
  -moz-transition: fill 0.4s ease;
  -ms-transition: fill 0.4s ease;
  -o-transition: fill 0.4s ease;
}

/*=====  End of 2. Globals  ======*/

/*=============================================
    =            3. Site Header            =
    =============================================*/
.navbar {
  background-color: $lightgray;
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;

  .navbar-inner {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .nav-wrapper {
    overflow-y: scroll;
    /* height: 100%; */
    overflow-x: hidden;
  }

  .navbar-brand-logo {
    position: relative;
    height: 91px;
    display: block;

    img {
      position: absolute;
      height: calc(100% - 30px);
      top: 15px;
      width: auto;
      transition: opacity 0.5s ease-in;
      -webkit-transition: opacity 0.5s ease-in;
      -moz-transition: opacity 0.5s ease-in;
      -ms-transition: opacity 0.5s ease-in;
      -o-transition: opacity 0.5s ease-in;
    }
  }

  &.sticky {
    box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.1);

    .navbar-brand-logo {
      position: relative;
    }

    .navbar-toggler {
      color: white !important;
      transition: 0.5s ease-in-out;
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -ms-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;

      .navbar-toggler-icon span {
        background: white !important;
      }
    }
  }

  a {
    color: black;
  }
}

/*=====  End of 3. Site Header  ======*/

/*=============================================
    =            4. Sections           =
=============================================*/

section {
  .container,
  .container-fluid-left,
  .container-fluid-right {
    padding-top: $section-padding-top-dektop;
    padding-bottom: $section-padding-bottom-dektop;
    @include media-breakpoint-down(lg) {
      padding-top: $section-padding-top-mobile;
      padding-bottom: $section-padding-bottom-mobile;
    }
  }

  &#first-stage {
    .video-color-overlay:before {
      background-color: #faf9fa;
    }
  }
  &#what-we-do {
    .container {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .text-icon-wrapper {
      color: $primary;
      margin-bottom: 40px;
    }
  }
  &#timeline {
    overflow: hidden;
    @include media-breakpoint-down(md) {
      display: unset !important;
    }
    .container {
      padding-top: $section-padding-top-dektop;
      padding-bottom: $section-padding-bottom-dektop;
    }
    .subline {
      color: $ba-black;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h3 {
      color: $primary;
      font-size: clamp(2.5rem, calc(4vw + 1rem), 5rem);
      line-height: clamp(2.75rem, calc(4vw + 1rem), 5.5rem);
    }
    img {
      @include media-breakpoint-down(md) {
        max-height: 240px;
      }
    }
    .video-color-overlay {
      @include media-breakpoint-down(md) {
        margin-top: -130vh;
        height: 130vh;
      }
    }

    /************************************************************
	Timeline
*************************************************************/
    .timeline {
      .h1-style {
        color: $primary;
        font-weight: 700;
      }
      h5:not(.h1-style) {
        font-size: 2rem;
        line-height: 2.6rem;
        font-size: clamp(1rem, calc(4vw + 1rem), 2rem);
        line-height: clamp(1.3rem, calc(4vw + 1rem), 2.6rem);
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        font-size: clamp(1rem, calc(4vw + 1rem), 1.25rem);
        line-height: clamp(0.84375rem, calc(4vw + 1rem), 1.6875rem);
      }
    }

    .timeline-dates .swiper-slide:after,
    .timeline-dates .swiper-slide-active:after {
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
    }

    /* timeline dates - desktop */
    @include media-breakpoint-up(lg) {
      .timeline-dates {
        padding-right: 20px;
        padding-bottom: 0.5rem;
        box-sizing: border-box;
        margin: 3rem 0 3.5rem;
        .swiper-wrapper {
          max-height: 216px;
        }
      }
      .timeline-dates .swiper-slide {
        text-align: right;
        color: #191919;
        cursor: pointer;
        font-size: 1.125rem;
        line-height: 27px;
        font-weight: 400;
        max-height: 0px;
        width: auto;
        height: 100%;
      }
      .timeline-dates .swiper-slide div {
        position: relative;
        opacity: 0.4;
        top: 0;
      }
      .timeline-dates .swiper-slide-active div {
        font-weight: 700;
        opacity: 1;
      }
      .timeline-dates.swiper-container:after {
        content: "";
        top: -50px;
        right: 0px;
        width: 2px;
        height: 100%;
        position: absolute;
        /* bottom: 0.3rem; */
        /* opacity: 0.5; */
        /* border-right: 2px solid #191919; */
        background: linear-gradient(
          0deg,
          rgba(35, 40, 45, 0) 0%,
          rgba(35, 40, 45, 1) 20%,
          rgba(35, 40, 45, 1) 80%,
          rgba(35, 40, 45, 0) 100%
        );
      }
      .timeline-dates .swiper-slide:after {
        content: "";
        right: -22.4px;
        width: 0.4rem;
        height: 0.4rem;
        position: absolute;
        background-color: #191919;
        border-radius: 50%;
      }
      .timeline-dates .swiper-slide-active:after {
        right: -26px;
        width: 0.8rem;
        height: 0.8rem;
        background-color: #191919;
      }
    }

    /* timeline dates - tablet und mobile */
    @include media-breakpoint-down(lg) {
      .timeline-dates {
        margin-top: 100px;
      }
      .timeline-dates.swiper-container:after {
        content: "";
        top: -16px;
        right: 0px;
        width: 100%;
        height: 2px;
        position: absolute;
        background: linear-gradient(
          90deg,
          rgba(35, 40, 45, 0) 0%,
          rgba(35, 40, 45, 1) 20%,
          rgba(35, 40, 45, 1) 80%,
          rgba(35, 40, 45, 0) 100%
        );
      }
      .timeline-dates .swiper-slide:after {
        content: "";
        top: -18px;
        width: 0.4rem;
        height: 0.4rem;
        position: absolute;
        background-color: #191919;
        border-radius: 50%;
      }
      .timeline-dates .swiper-slide-active:after {
        top: -21px;
        width: 0.8rem;
        height: 0.8rem;
        background-color: #191919;
      }
    }

    .timeline-contents {
      height: 80%;
      width: 100%;
    }

    /* Arrows */
    .timeline-buttons-container {
      position: relative;
      top: -29px;
      left: 0px;
      right: 0px;
      width: 100px;
      @include media-breakpoint-up(xl) {
      }
      @include media-breakpoint-up(lg) {
      }
      @include media-breakpoint-down(lg) {
        margin: auto;
        bottom: -160px;
        top: 30px;
      }
      @include media-breakpoint-up(md) {
      }
      @include media-breakpoint-down(md) {
      }
      @include media-breakpoint-up(sm) {
      }
      @include media-breakpoint-down(sm) {
      }
      @include media-breakpoint-down(xs) {
      }
    }
    .timeline-button-next,
    .timeline-button-prev {
      position: absolute;
      top: 50%;
      width: auto;
      height: auto;
      margin-top: 0;
      z-index: 2;
      cursor: pointer;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      font-size: 13px;
      padding: 5px;
    }
    .swiper-button-disabled {
      opacity: 0;
      width: 0px;
    }
    .timeline-button-next {
      right: 0;
    }
    .timeline-button-prev {
      left: 0;
    }
    /* .timeline-button-next:hover,
    .timeline-button-prev:hover {
      &:before {
      }
    } */
    .timeline-button-next:before,
    .timeline-button-prev:before {
      font-family: "Ionicons";
      color: rgba(255, 255, 255, 1);
      position: absolute;
      top: 20%;
      transform: translate(-50%, -50%);
      margin-right: -50%;
    }
    .timeline-button-next:before {
      content: url(../img/arrow-circle-right.svg);
      width: 50px;
      height: 50px;
      left: 53%;
    }
    .timeline-button-prev:before {
      content: url(../img/arrow-circle-right.svg);
      width: 50px;
      height: 50px;
      left: 0%;
      top: -20px;
      transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
      -o-transform: scaleX(-1);
    }

    .swiper-container {
      width: 100%;
      height: 100%;
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
    .swiper-wrapper {
      align-items: flex-end;
    }

    .swiper-slide {
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      flex-direction: column;
    }

    .video-color-overlay:before {
      /* background: linear-gradient(
        72.77deg,
        rgba(255, 255, 255, 0) 1.97%,
        rgba(255, 255, 255, 0.5) 41.27%,
        rgba(255, 255, 255, 0.9) 99.44%
      ); */
      background-color: #faf9fa;
    }
  }
  &#services {
    background-color: #fbf9fb;
    .space-holder {
      position: relative;
      width: 100%;
      padding-bottom: $section-padding-bottom-dektop;
      @include media-breakpoint-down(lg) {
        height: unset !important;
      }
    }
    .section-title {
      padding-bottom: 37px;
    }

    @include media-breakpoint-up(lg) {
      .sticky {
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        /* @include media-breakpoint-up(lg) {
          max-height: 700px;
        } */
      }

      .horizontal {
        /* position: absolute; */
        position: relative;
        height: 100%;
        will-change: transform;
      }

      .cards {
        position: relative;
        height: 100%;
        padding: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .card {
      border: 0px;
      background-color: transparent;
      position: relative;
      height: auto;
      margin-bottom: 0px;
      flex-shrink: 0;
      @include media-breakpoint-up(lg) {
        margin-right: 75px;
        width: 68vw;
      }
      /* @include media-breakpoint-down(sm) {
        width: 100vw;
      } */
      .col-md-5 {
        max-width: 400px;
      }
      .col-md-7 {
        align-items: center;
        display: flex;
        max-width: 600px;
      }
    }

    .card-body {
      @include media-breakpoint-up(md) {
        padding-left: 2rem;
      }
      @include media-breakpoint-down(md) {
        padding-left: 0rem;
      }
    }

    .card-title {
      font-size: clamp(1.875rem, calc(4vw + 0rem), 3.75rem);
      line-height: clamp(2.1375rem, calc(4vw + 0rem), 4.275rem);
      margin-bottom: 24px;
    }
    figure {
      width: 100%;
      margin-bottom: 0px;
    }
    @include media-breakpoint-down(md) {
      .card .figure-img {
        aspect-ratio: 3/2;
        max-height: 280px;
        width: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }
  }
  &#follow-us {
    .social-media-links-wrapper {
      margin-top: 26px;
      li:hover {
        svg path {
          fill: $primary;
        }
      }
    }
  }
  &#to-website {
    .container {
      padding-top: $section-padding-top-dektop;
      padding-bottom: $section-padding-bottom-dektop;
    }
    .video-color-overlay:before {
      background-color: #2da05f;
    }
  }
  /* section video background START */
  &.section-video-bg {
    display: grid;

    .video-color-overlay,
    .container {
      grid-area: 1 / 1;
    }
    .container {
      z-index: 1;
    }
    video {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  .video-color-overlay:before {
    content: "";
    opacity: 0.8;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
  }
  .video-color-overlay {
    width: 100%;
    height: 100%;
    position: relative;
    video {
      height: 100%;
    }
  }
  /* section video background END */
}

/*=====  End of 4. Sections  ======*/

/*=============================================
    =            5. Modules            =
    =============================================*/

/*=====  End of 5. Modules  ======*/

/*=============================================
    =            6. 404 Page            =
    =============================================*/

/*=====  End of 6. 404 Page  ======*/

/*=============================================
    =            7. Impressum - Datenschutz       =
    =============================================*/

/*=====  End of 7. Impressum - Datenschutz  ======*/
/*=============================================
    =            8. Site Footer            =
    =============================================*/
.footer {
  .footer-inner {
    width: 100%;
    /* padding: 0 30px; */
    overflow: hidden;

    img {
      max-height: 60px;
    }

    .footer-contact-wrapper {
      padding-left: 60px;
    }

    .nav-item {
      width: calc(100% + 40px);
    }
  }
  h5 {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 21.6px;
  }
  p,
  a,
  li {
    font-size: 1.5rem;
    font-size: clamp(1rem, calc(4vw + 0rem), 1.5rem);
    line-height: clamp(1.2rem, calc(4vw + 0rem), 1.8rem);
    font-weight: 400;
    padding-left: 0px;
  }

  color: $ba-black;

  padding-top: 3.5rem;
  background-color: $lightgray;
  a {
    color: $ba-black;
  }
  .copyright-wrapper p {
    margin-top: 3rem;
    font-weight: 400;
  }
}
.sub-footer {
  ul li.nav-item.first-item a {
    padding-right: 8px;
  }
  a {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1rem;
    border-bottom: 0px solid black;
  }
  @include media-breakpoint-up(md) {
    ul li.nav-item:not(.first-item) {
      padding-left: 40px;
    }
  }
  a:hover {
    border-bottom: 0px solid black;
  }
}
/*=====  End of 8. Site Footer  ======*/

/*=============================================
    =            9. Media Queries            =
    =============================================*/
@include media-breakpoint-up(xl) {
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-up(md) {
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-down(sm) {
}

@include media-breakpoint-down(xs) {
}

/*=====  End of 9. Media Queries  ======*/

/* make bootstrap container one site stretch / fluid */
@mixin make-container-fluid-left-right($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
}
@mixin make-container-fluid-left(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      margin-right: calc((100% - #{$container-max-width}) / 2);
      width: calc(100% - ((100% - #{$container-max-width}) / 2));
    }
  }
}
@mixin make-container-fluid-right(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      margin-left: calc((100% - #{$container-max-width}) / 2);
      width: calc(100% - ((100% - #{$container-max-width}) / 2));
    }
  }
}
.container-fluid-left {
  @include make-container-fluid-left-right();
  @include make-container-fluid-left();
}
.container-fluid-right {
  @include make-container-fluid-left-right();
  @include make-container-fluid-right();
}

/*=====  Start - These code is only for test something  ======*/

/*=====  End - These code is only for test something  ======*/
